import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { MemoryRouter } from 'react-router-dom';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiLink } from '../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uilink"
    }}>{`UiLink`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/text/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component to render a wrapper around links to provide styling, you have to provide the link element, this was done so we could support
multiple use cases Link from react router, or Link from next JS or any other router you are using.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/text`}</p>
    </blockquote>
    <h2 {...{
      "id": "uilink-1"
    }}>{`UiLink`}</h2>
    <Playground __position={1} __code={'<UiLink>\n  <a href=\"https://uireact.io/\">Some text</a>\n</UiLink>'} __scope={{
      props,
      DefaultLayout,
      Props,
      MemoryRouter,
      packageJson,
      Playground,
      UiLink,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLink mdxType="UiLink">
    <a href="https://uireact.io/">Some text</a>
  </UiLink>
    </Playground>
    <h2 {...{
      "id": "uilink-with-color-category"
    }}>{`UiLink with color category`}</h2>
    <Playground __position={2} __code={'<UiLink category=\"error\">\n  <a href=\"https://uireact.io/\">Some text</a>\n</UiLink>{\' \'}\n<UiLink category=\"positive\">\n  <a href=\"https://uireact.io/\">Some text</a>\n</UiLink>{\' \'}\n<UiLink\n  handleClick={() => console.log(\'Link Clicked\')}\n  category=\"negative\"\n  fontStyle=\"italic\"\n>\n  <a href=\"https://uireact.io/\">Some text</a>\n</UiLink>'} __scope={{
      props,
      DefaultLayout,
      Props,
      MemoryRouter,
      packageJson,
      Playground,
      UiLink,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLink category="error" mdxType="UiLink">
    <a href="https://uireact.io/">Some text</a>
  </UiLink>{' '}
  <UiLink category="positive" mdxType="UiLink">
    <a href="https://uireact.io/">Some text</a>
  </UiLink>{' '}
  <UiLink handleClick={() => console.log('Link Clicked')} category="negative" fontStyle="italic" mdxType="UiLink">
    <a href="https://uireact.io/">Some text</a>
  </UiLink>
    </Playground>
    <h2 {...{
      "id": "uitext-with-default-coloration"
    }}>{`UiText with default coloration`}</h2>
    <Playground __position={3} __code={'<UiLink coloration=\"dark\">\n  <a href=\"https://uireact.io/\">Will always render with DARK coloration</a>\n</UiLink>\n<br />\n<UiLink coloration=\"light\">\n  <a href=\"https://uireact.io/\">Will always render with LIGHT coloration</a>\n</UiLink>{\' \'}'} __scope={{
      props,
      DefaultLayout,
      Props,
      MemoryRouter,
      packageJson,
      Playground,
      UiLink,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLink coloration="dark" mdxType="UiLink">
    <a href="https://uireact.io/">Will always render with DARK coloration</a>
  </UiLink>
  <br />
  <UiLink coloration="light" mdxType="UiLink">
    <a href="https://uireact.io/">Will always render with LIGHT coloration</a>
  </UiLink>{' '}
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiLink} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      